import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomSnackbar(props) {
    const [state, setState] = React.useState({
        open: true,
    });

    let isMounted = true;
    const handleClose = () => {
        if(!isMounted) return;
        props.setMessage("")
        setState({ ...state, open: false });
        return () => {
            isMounted = false;
        }
    };
    
    let severity = props.severity
    if(severity === undefined) severity = "error"
    let message = props.message

    return (
        <Snackbar open={state.open} onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
}
