import React, {useState} from "react";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Snackbar } from "../snackbar";

import axios from 'axios';

function Nim(props) {
  const [message, setMessage] = useState("");
  const [nim, setNim] = useState("");
  const token = props.token

  return (
    <>
    {message !== "" ? <Snackbar message={message} setMessage={setMessage}/> : <></>}
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      flexDirection: 'row',
      textAlign: 'center',
    }}>
    <div>
    <form noValidate autoComplete="off" onSubmit={(e) => {
      submit(nim, token, setMessage)
      e.preventDefault()
    }}>
      <div>
      <TextField id="standard-basic" label="NIM" value={nim} onChange={(e) => {
        setNim(e.target.value)
      }} />
      </div>
      <div style={{margin: '1em auto'}}>
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
      </div>
    </form>
    </div>
  </div>
  </>
  );
}

function submit(nim, token, setMessage) {
  axios.put(`${process.env.REACT_APP_BACKEND_URL}/user/nim`, {
    nim: nim
  }, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  .then(function (response) {
    window.location.replace(`${process.env.REACT_APP_BASE_URL}/`);
  }).catch((err) => {
    console.log(err);
    setMessage(err.response.data);
  });
}

export default Nim;
