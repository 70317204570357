import React, {useState, useEffect} from "react";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import {useParams} from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import { Snackbar } from "../snackbar";
import './material.css';

import CodeBlock from "./CodeBlock";

function Material(props) {
  const [message, setMessage] = useState("");
  let { id } = useParams();
  const [data, setData] = useState([]);
  const [idx, setIdx] = useState(0);

  const token = props.token;

  useEffect(() => {
    let i = localStorage.getItem(`material-${id}`);
    if(i == null) {
      i = "0";
    }

    let info = {};
    info["action"] = "open";
    info["idx"] = parseInt(i);
  
    setIdx(parseInt(i));
    log(token, id, info);
  }, [id, token]);

  useEffect(() => {
    let isMounted =true;
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/material/${id}`, {
      headers: {
        'Authorization': `Bearer ${props.token}`
      }
    })
    .then(function (response) {
      if(isMounted) setData(response.data.markdown.data);
    }).catch((err) => {
      console.log(err);
      if(err.response.data.trim() === "update nim") {
        window.location.replace(`${process.env.REACT_APP_BASE_URL}/nim`);
      }
      if(isMounted) setMessage(err.response.data);
    });
    return () => {
      isMounted = false;
    }
  }, [id, props.token]);

  const source = data[idx];

  return (
    <>
    {message !== "" ? <Snackbar message={message} setMessage={setMessage}/> : <></>}
    <div style={{
      margin: '1em auto',
      padding: '2em',
      maxWidth: '85ch',
      minWidth: '25ch',
      lineHeight: '1.5'
    }}>
    <Paper elevation={3} style={{padding: '1em'}}>
     <ReactMarkdown source={source} 
      renderers={{ code: CodeBlock }}/>
    </Paper>
    </div>
    {idx > 0 ?
    <div className="nav-button left-nav" onClick={()=>{
      const nextIdx = idx-1;
      let info = {}
      info["action"] = "open"
      info["idx"] = nextIdx
      log(token, id, info).then(() => {
        window.scrollTo(0, 0)
        localStorage.setItem(`material-${id}`, (nextIdx)+"");
        setIdx(nextIdx);
      }).catch((err)=> {
        setMessage("log failed. please connect to internet");
      });
    }}>&#8249;</div> : <></>
    }
    {idx < data.length-1 ?
    <div className="nav-button right-nav" onClick={()=>{
      const nextIdx = idx+1;
      let info = {}
      info["action"] = nextIdx === data.length-1 ? "done" : "open"
      info["idx"] = nextIdx
      log(token, id, info).then(() => {
        window.scrollTo(0, 0)
        localStorage.setItem(`material-${id}`, (nextIdx)+"");
        setIdx(nextIdx);
      }).catch((err)=> {
        setMessage("log failed. please connect to internet");
      });
    }}>&#8250;</div> : <></>
    }
    </>
  );
}

async function log(token, id, info) {
  await axios.post(`${process.env.REACT_APP_BACKEND_URL}/material/${id}/log`, info, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  .then(function (response) {
    console.log(response);
  }).catch((err) => {
    console.log(err);
    throw err;
  });
}

export default Material;
