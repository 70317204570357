import React, { useState } from "react";
import "./app.css";
import { Login } from "../login";
import { Nim } from "../nim";
import { Catalog } from "../catalog";
import { Material } from "../material";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

function App(props) {
  let _token = localStorage.getItem("token");
  let need_nim = false;
  if(_token == null) {
    const query = new URLSearchParams(window.location.search);
    _token = query.get('token');
    need_nim = query.get('need_nim') === "true";
    if(_token != null) {
      localStorage.setItem("token", _token);
    }
  }

  const [token, setToken] = useState(_token);

  return (
    <>
    <Router>
      <Route exact path = "/">
        {token == null ? <Login/> : 
        need_nim ?
        <Redirect to="/nim" /> :
        <Catalog token={token} setToken={setToken}/>}
      </Route>
      <Route path = "/nim">
        {token == null ? <Redirect to="/" /> : <Nim token={token}/>}
      </Route>
      <Route path = "/topic/:id">
        {token == null ? <Redirect to="/" /> : <Material token={token}/>}
      </Route>
   </Router>
   </>
  )
}

export default App;
