import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from "react-router-dom";
import { AppBar } from "../appbar";
import { Snackbar } from "../snackbar";
import axios from "axios";


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function Catalog(props) {
  const [message, setMessage] = useState("");
  const [rows, setRows] = useState([]);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    let isMounted =true;
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/material`, {
      headers: {
        'Authorization': `Bearer ${props.token}`
      }
    })
    .then(function (response) {
      if(isMounted) setRows(response.data);
    }).catch((err) => {
      console.log(err);
      if(err.response.data.trim() === "update nim") {
        window.location.replace(`${process.env.REACT_APP_BASE_URL}/nim`);
      }
      if(isMounted) setMessage(err.response.data);
    })
    return () => {
      isMounted = false;
    }
  }, [props.token]);

  useEffect(() => {
    history.push({
      pathname: '/'
    })
  }, [history]);

  return (
    <>
    {message !== "" ? <Snackbar message={message} setMessage={setMessage}/> : <></>}
    <AppBar token={props.token} setToken={props.setToken}/> 
    <TableContainer component={Paper} style={{padding:'1em'}}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{fontWeight:'bold'}}>Session</TableCell>
            <TableCell style={{fontWeight:'bold'}}>Topic</TableCell>
            <TableCell style={{fontWeight:'bold'}}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 && rows.map((row) => (
            <TableRow key={row['id']}>
              <TableCell>{row['topic']}</TableCell>
              <TableCell>{row['session']}</TableCell>
              <TableCell><Button variant="contained" onClick={()=>
                openInNewTab('/topic/' + row['id'])
              }>Read</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}


export default Catalog;