import React from "react";

import { GithubLoginButton } from "react-social-login-buttons";

function Login(props) {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh'
    }}>
    <GithubLoginButton onClick={() => doLogin()} style={{width: '210px'}}/>
    </div>
  );
}

function doLogin() {
  window.location.replace(`${process.env.REACT_APP_BACKEND_URL}/auth/github/login`);
}

export default Login;
